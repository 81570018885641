<template>
  <div>
    <static-fullscreen-card v-show="!idItemShow">
      <template v-slot:header></template>
      <template v-slot:title>
        <v-row class="ma-1">
          <a-btn-menu v-if="getAccess('task.comments', null, true)" size="lg" icon="far fa-caret-square-down" :items="panelMenuItems" @click="onClickMenu" />
          <v-btn-toggle v-model="sortType" mandatory borderless dense @change="onTableRefresh()">
            <v-btn value="id-asc">
              <span class="hidden-md-and-down">Старые</span>
              <v-icon right> mdi-sort-clock-ascending-outline </v-icon>
            </v-btn>
            <v-btn value="id-desc" class="mx-1">
              <span class="hidden-md-and-down">Новые</span>
              <v-icon right> mdi-sort-clock-descending-outline </v-icon>
            </v-btn>
            <v-btn value="comment" v-if="getAccess('task.comments', null, true)">
              <span class="hidden-md-and-down">Сообщения</span>
              <v-icon right> mdi-comment-eye-outline </v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-btn-toggle v-model="taskViewMode" dense multiple borderless @change="onTableRefresh()">
            <v-btn class="mx-1">
              <v-icon left class="mx-0"> {{ taskViewMode.includes(0) ? "mdi-checkbox-outline" : "mdi-checkbox-blank-outline" }} </v-icon>
              <span class="hidden-md-and-down">Мои задачи</span>
              <v-icon right> mdi-account </v-icon>
            </v-btn>
            <v-btn>
              <v-icon left class="mx-0"> {{ taskViewMode.includes(1) ? "mdi-checkbox-outline" : "mdi-checkbox-blank-outline" }} </v-icon>
              <span class="hidden-md-and-down">Наблюдатель</span>
              <v-icon right> mdi-eye </v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-btn-toggle v-model="taskViewMode" dense multiple borderless @change="onTaskPaint()">
            <v-btn class="ml-1" :value="2">
              <v-icon left class="mx-0"> {{ taskViewMode.includes(2) ? "mdi-checkbox-outline" : "mdi-checkbox-blank-outline" }} </v-icon>
              <v-icon right> mdi-timer-settings-outline </v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-row>
      </template>
      <template v-slot:actions>
        <div v-if="modeRedirect">
          <a-btn-add @click="tryRedirect()" :disabled="loading" icon="fas fa-people-arrows" title="" />
          <a-btn-add @click="modeRedirect = false" :disabled="loading" icon="fas fa-ban" title="" />
        </div>
        <div v-else>
          <a-btn-refresh color="primary" @click="updateData()" />
          <a-btn-add @click="modeRedirect = true" :disabled="loading" icon="fas fa-people-arrows" title="" />
        </div>
        <portal to="v-main"> </portal>
      </template>
      <a-table-f-api
        ref="table"
        :api="url"
        :model="model"
        :useQuery="false"
        :defaults="defaults"
        :selectedRows="selectedRows"
        @click="
          counter += 1;
          onDetectClick($event);
        "
        @change-filter="onChangeFilter($event)"
        @rowContextMenu="onRowContextMenu($event)"
        @dataUpdated="dataMdf()"
      />
      <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" />
    </static-fullscreen-card>
    <ViewItem v-if="idItemShow" :idShow="idItemShow" @close="itemShowClose($event)" />
    <task-dialog v-model="showTaskDialog" :id="idEdit"></task-dialog>
    <RedirectTask v-if="showRedirectDialog" v-model="showRedirectDialog" @redirect="onRedirect($event)" />
    <context-menu ref="rowContextMenu" :items="contextMenuItems" @clickData="onContextMenuClick($event)" />
  </div>
</template>

<script>
import { getAccess, doubleClickDetect, popupMenu } from "@/components/mixings";

export default {
  mixins: [getAccess, doubleClickDetect, popupMenu],
  components: {
    taskDialog: () => import("./dialogs/taskBuyingDialog"),
    ViewItem: () => import("./views/tasksBuyingView"),
    RedirectTask: () => import("./dialogs/taskRedirectDialog"),
  },
  data() {
    return {
      taskViewMode: [0, 1],
      sortType: "comment",
      view: 1,
      idEdit: 0,
      loading: false,
      idItemShow: 0,
      redirectList: null,
      modeRedirect: false,
      showRedirectDialog: false,
      dialog: false,
      showTaskDialog: false,
      m: this.$store.getters["config/get"].models.buying,
      statuses: this.$store.getters["config/get"].models.buying.statuses,
      url: "/mechti/buying",
      filterName: "buying",
      panelMenuItems: [
        {
          text: "Отметить все комментарии прочитанными",
          action: async () => {
            await this.$axios.post("mechti/buying/set_viewed_comments");
            this.$store.dispatch("state/badges", "buying_comments");
            this.updateData();
          },
        },
      ],
      contextData: null,
      categories: null,
      objectWork: {},
    };
  },
  created() {
    const filters = this.$root.getSessionFilter(this.filterName);
    this.$root.title = "Покупки по чек-листу" + (filters ? "(отфильтрованы)" : "");
    if ((this.$store.getters["config/getLocalParams"]?.["buying.taskViewMode"] || []).includes(2)) this.taskViewMode.push(2);
    //this.defaults.sessionFilters = filters || null;
    this.getCategories();
  },
  computed: {
    defaults() {
      let filters = {};
      let sort = [];
      let where = {};
      if (this.taskViewMode.includes(0) && this.taskViewMode.includes(1)) {
      } else if (this.taskViewMode.includes(0)) {
        //мои
        where = { OR: [{ user_id: this.$root.profile.id }, { createdby_id: this.$root.profile.id }] };
      } else if (this.taskViewMode.includes(1)) {
        //наблюдатель
        where = {
          AND: [{ user_id: { condition: "<>", value: `${this.$root.profile.id}` } }, { createdby_id: { condition: "<>", value: `${this.$root.profile.id}` } }],
        };
      }
      if (this.sortType == "id-asc") sort.push({ key: "id", order: "ASC", default: true });
      if (this.sortType == "comment") sort.push({ key: "unviewedscomments_total", order: "DESC", default: true });
      if (this.sortType == "id-desc" || this.sortType == "comment") sort.push({ key: "id", order: "DESC", default: true });
      const sessionFilters = this.$root.getSessionFilter(this.filterName) || null;
      // if (this.isTaskSelf) filters;
      return {
        sort,
        filters,
        sessionFilters,
        paramName: "buying",
        where,
      };
    },
    contextMenuItems() {
      let res = [
        { name: "viewThis", title: `Отметить эту задачу просмотренной`, disabled: !!this.contextData?.row?.viewedon },
        { name: "viewAll", title: `Отметить ВСЕ задачи просмотренными` },
        { name: "viewComments", title: `Отметить комментарии прочитанными`, disabled: !this.contextData?.row?.unviewedscomments_total },
      ];
      return res;
    },
    statusList() {
      let list = [];
      //return this.statuses;
      let idx;
      let type = {
        actual: "*Актуальное",
        new: "*Новые",
        progress: "*В процессе",
        ready: "*Завершенные",
        cancel: "*Отмененные",
      };
      let text;
      for (const s of this.statuses.filter((el) => el.status)) {
        text = type[s.status] || s.status;
        idx = list.findIndex((el) => el.text == text);
        if (idx > -1) {
          list[idx].value.push(s.value);
        } else {
          list.push({ value: [s.value], text });
        }
      }
      list.push({
        value: this.statuses
          .filter((s) => ["new", "progress"].includes(s.status))
          .map((s) => {
            return s.value;
          }),
        text: "*Актуальное",
      });

      for (const s of this.statuses) {
        idx = list.findIndex((el) => el.text == s.text);
        if (idx > -1) {
          list[idx].value.push(s.value);
        } else {
          list.push({ value: [s.value], text: s.text });
        }
      }
      return list;
    },
    model() {
      /**заменяем отображение даты конца на др элемент. с подсветкой */
      let model = JSON.parse(JSON.stringify(this.m.list));
      const status = this.statuses.filter((el) => {
        return ["ready", "cancel"].includes(el?.status);
      });
      model.forEach((el) => {
        if (el.name == "date_end") {
          el.type = "dateColor";
          el.status = status.map((s) => {
            return s.value;
          });
        }
        if (el.name == "user_name") {
          // el.textColor = "user_viewedon";
          el.textColor = (values) => {
            return values.user_viewedon ? "" : "red";
          };
        }
        if (el.name == "status") {
          el.search = this.statusList;
        }
        if (el.name == "type") {
          let comment = this.getAccess("task.comments", null, true);
          if (!comment) el.badgeField = null;
        }
        if (el.name == "schedule") {
          el.hidden = true; //!this.taskViewMode.includes(2);
        }
        if (el?.sourceList) {
          let list = this.m[el.sourceList] || [];
          if (el.name == "type")
            list = list.filter((f) => {
              return f.value == "construction-goods";
            });
          el.options = list;
        }
      });

      model = [
        {
          name: "checked",
          type: "checkbox",
          width: 35,
          isDisableFunc: (r) => {
            return ([0, 1, 100, 101, 200, 201].includes(r.status) && this.canRedirect) || r.user_id == this.$root.profile.id;
            return true;
          },
          hidden: !this.modeRedirect,
        },
        ...model,
      ];
      return model;
    },
    canRedirect() {
      return this.getAccess("task.redirect");
    },
  },
  watch: {
    showTaskDialog() {
      if (!this.showTaskDialog) {
        this.updateData();
        this.$store.dispatch("state/badges", "buying");
      }
    },
  },
  methods: {
    onTaskPaint() {
      this.$store.commit("config/setLocalParams", {
        name: "buying.taskViewMode",
        value: this.taskViewMode,
      });
      if (!this.taskViewMode.includes(2)) {
        this.dataMdf();
      } else {
        this.dataMdf();
      }
    },
    getColor(row) {
      const currentDate = new Date();
      const date_end = new Date(row.date_end);
      const date_start = new Date(row.date_start);
      if (date_end > currentDate && date_start < currentDate) return "#C8E6C9"; //green lighten-4
      if (date_end < currentDate) return "#FFCDD2"; //red lighten-4
      if (date_start > currentDate) return "#F0F4C3"; //lime lighten-4

      return null;
    },
    getColor_(planDate, v) {
      const currentDate = new Date();
      const planDateObj = new Date(planDate);
      // Вычисляем разницу в днях между текущей датой и плановой датой
      const differenceInTime = currentDate.getTime() - planDateObj.getTime();
      const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24)) - v;

      let color = "";
      //console.log(planDate, currentDate, differenceInDays);
      if (differenceInDays === 1 || differenceInDays === -1 || differenceInDays === 0) {
        color = "#A5D6A7";
      } else if (differenceInDays < -1) {
        color = "#FFF59D";
      } else if (differenceInDays > 1) {
        color = "#EF9A9A";
      }

      return color;
    },
    async getCategories() {
      try {
        let resp = await this.$axios.get("accounting/dir/service_category", { params: { filters: { type: 2 } } });
        let res = [];
        if (resp.data.data)
          res = resp.data.data
            .filter((c) => {
              return c?.data?.buying_rules && c.data.buying_rules.find((r) => r.type == 2);
            })
            .map((c) => {
              return { id: c.id, rule: c.data.buying_rules.find((r) => r.type == 2) };
            });
        let cat = {};
        res.forEach((c) => {
          cat[c.id] = c.rule;
        });
        this.categories = cat;
      } catch (error) {
        this.$root.$emit("show-info", { type: "error", text: "Произошла ошибка" });
        console.error(error);
      }
    },
    async dataMdf() {
      let name = "table";
      const status = this.statuses.filter((s) => ["new", "progress"].includes(s.status)).map((s) => s.value);
      if (!this.$refs?.[name].data) return;
      //return;
      for (let i = 0; i < this.$refs[name].data.length; i++) {
        const d = this.$refs[name].data[i];
        if (status.includes(d.status)) {
          if (d._row && !this.taskViewMode.includes(2)) {
            d._row = null;
          }
          if (!d._row && this.taskViewMode.includes(2)) {
            let color = this.getColor({ date_end: d.date_end, date_start: d.date_start });
            if (d.status == 281) {
              this.$axios.post("/report_sql", { name: "orderGoodsPaymentInfo", params: { id: d.id } }).then((r) => {
                if (r.data && r.data.data && r.data.data.length > 0) {
                  let color = "#90CAF9"; //blue lighten-3
                  d._row = { style: `background-color: ${color};`, field: color };
                  this.$refs[name].$forceUpdate();
                }
              });
              //      orderGoodsPaymentInfo        const work = await this.getObjectWork(d.object_id, rule.service_id);
            }
            d._row = { style: `background-color: ${color};`, field: color };
          }
          this.$refs[name].$forceUpdate();
        }
      }
    },

    async getObjectWork(object_id, c) {
      if (!this.objectWork?.[object_id]) {
        try {
          let resp = await this.$axios.get("mechti/construction-schedule", { params: { filters: { object_id, status: { condition: "not in", value: [-2, -1] } } } });
          if (resp.data.data) {
            this.objectWork[object_id] = resp.data.data;
          } else {
            this.objectWork[object_id] = [];
          }
        } catch (error) {
          this.$root.$emit("show-info", { type: "error", text: "Произошла ошибка. Получение работ на объекте" });
          console.error(error);
        }
      }
      return (this.objectWork?.[object_id] || []).find((w) => w.service_id == c);
    },
    tryRedirect() {
      let error = "";
      this.redirectList = null;
      let rows = [];
      for (let row of this.$refs.table.data.filter((el) => {
        return el?.checked;
      })) {
        rows.push(row);
      }
      if (!rows.length) {
        error = "Ничего не выбранно!";
        return false;
      }

      if (!error)
        rows = rows.filter((el) => {
          console.log("row", el.id, el.status);
          return [0, 1, 100, 101, 200, 201].includes(el.status);
        });

      let userOld;
      if (!error)
        rows.forEach((el) => {
          if (userOld && el.user_id != userOld) {
            error = "Выбраны задачи разных пользователей!";
            return false;
          }
          userOld = el.user_id;
        });
      if (!error) this.redirectList = rows;

      if (!error) this.showRedirectDialog = true;
      else {
        this.$root.$emit("show-info", {
          text: error,
          type: "error",
        });
      }
    },

    async onRedirect(data) {
      if (!this.redirectList) return;
      let d = [];
      this.redirectList.forEach((el) => {
        d.push(Object.assign({}, data, { id: el.id }));
      });

      this.loading = true;
      let response = await this.$axios.post("/mechti/buying/redirect", d);
      this.loading = false;
      let status = response.data.status == "ok";
      if (status) {
        this.$root.$emit("show-info", {
          text: "Задачи переназначена",
        });
        this.updateData();
      } else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при переназначении",
        });
      }
    },
    async onContextMenuClick(e) {
      if (e.item.name == "viewThis") {
        await this.$axios.post("/mechti/buying/set_viewed", { ids: [e.data.id] });
        this.$store.dispatch("state/badges", "buying_items");
        this.$refs.table.updateRowData(e.data.id);
      }
      if (e.item.name == "viewAll") {
        this.loading = !this.loading;
        await this.$axios.post("/mechti/buying/set_viewed");
        this.$store.dispatch("state/badges", "buying_items");
        this.updateData();
      }
      if (e.item.name == "viewComments") {
        await this.$axios.post("mechti/buying/set_viewed_comments", { ids: [e.data.id] });
        this.$store.dispatch("state/badges", "buying_comments");
        this.$refs.table.updateRowData(e.data.id);
      }
    },
    onRowContextMenu(e) {
      this.contextData = e;
      this.$refs.rowContextMenu.showMenu(e.event, e.row);
    },
    onClickMenu(e) {
      if (e.action) e.action();
    },
    onTableRefresh() {
      setTimeout(() => {
        this.$refs.table.init();
      }, 100);
    },
    itemShowClose() {
      this.loading = true;
      this.$refs.table.updateRowData(this.idItemShow);
      this.$store.dispatch("state/badges", "buying");
      this.loading = !true;
      this.idItemShow = 0;
      this.dataMdf();
    },

    updateData() {
      this.$refs.table.updateData();
    },

    onSingleClick(d) {
      if (d.field?.isMenu) {
        if (d.field.name == "type") {
          let menuItems = [
            {
              name: "type",
              title: "Отметить комментарии прочитанными",
              action: async () => {
                await this.$axios.post("mechti/buying/set_viewed_comments", { ids: [d.row.id] });
                this.$store.dispatch("state/badges", "buying_comments");
                this.$refs.table.updateRowData(d.row.id);
              },
            },
          ];
          console.log("tyyyype");
          if (d.row.unviewedscomments_total) this.showPopupMenu(d.field.name, d.row, d.event, { menuItems });
        } else this.showPopupMenu(d.field.name, d.row, d.event, null);
      }
    },
    onDoubleClick(d) {
      if (this.showCronList) {
        this.showCronDialogFun(d.row.id);
        this.showCronDialog = true;
      } else {
        this.idItemShow = d.row.id;
        return;
        this.$router.push({ name: "tasks_view", params: { id: d.row.id } });
      }
    },

    createNew() {
      this.showTaskDialogFun(0);
    },

    showTaskDialogFun(id) {
      this.idEdit = id;
      this.showTaskDialog = true;
    },
    createNewCron() {
      this.showCronDialogFun(0);
    },
    showCronDialogFun(id) {
      this.idEdit = id;
      this.showCronDialog = true;
    },
    async updateTask(row) {
      await this.$axios.post(this.url, row);
      this.$root.$emit("show-info", {
        text: "Данные записаны",
      });
    },
    onChangeFilter(f) {
      console.log("onChangeFilter", this.filterName, f);
      this.$root.setSessionFilter(this.filterName, f);
    },
  },
};
</script>
